import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect, useRef } from 'react'
import { io, Socket } from 'socket.io-client'

import { useOpointAuth } from '@opoint/authjs-react'
import {
  getNotificationsAlertCountRetrieveQueryKey,
  getNotificationsListQueryKey,
} from '../../../api/notifications/notifications.ts'
import config from '../../../opoint/common/config.ts'
import { Notification, Notifications } from '../../../opoint/flow.ts'
import { useAppDispatch } from '../../hooks/useAppDispatch.ts'

const handleUpdateNotifications = (
  oldData: InfiniteData<Notifications>,
  notification: Notification,
  type: 'updated' | 'created',
) => {
  if (!oldData) {
    return oldData
  }

  const results = [...oldData.pages[0].results]
  const notificationIndex = results.findIndex((item) => item.id === notification.id)

  if (notificationIndex !== -1) {
    // Replace the existing notification
    results[notificationIndex] = { ...notification, type }
  } else {
    // Add new notification
    results.unshift({ ...notification, type })
  }

  return {
    ...oldData,
    pages: [
      {
        ...oldData.pages[0],
        results,
      },
    ],
  }
}

const useNotificationSocket = () => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const queryKey = getNotificationsListQueryKey()
  const countQueryKey = getNotificationsAlertCountRetrieveQueryKey()
  const auth = useOpointAuth()

  const socket = useRef<Socket>(
    io(config.url.socketio('/notifications'), {
      transports: ['websocket', 'polling'],
      autoConnect: false,
    }),
  )

  const handleNotification = useCallback(
    (notification: Notification, type: 'created' | 'updated') => {
      // Dispatching this action in order to continue the report generation process, to the fifth and final step.
      dispatch({ type: 'NOTIFICATIONS_SOCKET_SUCCESS', payload: { ...notification, type } })

      void queryClient.invalidateQueries(countQueryKey)
      void queryClient.invalidateQueries(queryKey)
    },
    [countQueryKey, dispatch, queryClient, queryKey],
  )

  useEffect(() => {
    function onUpdated(notification: Notification) {
      handleNotification(notification, 'updated')
      queryClient.setQueryData<InfiniteData<Notifications>>(queryKey, (currentData) =>
        // @ts-expect-error: Muted so we could enable TS strict mode
        handleUpdateNotifications(currentData, notification, 'updated'),
      )
    }

    function onCreated(notification: Notification) {
      handleNotification(notification, 'created')
      queryClient.setQueryData<InfiniteData<Notifications>>(queryKey, (currentData) =>
        // @ts-expect-error: Muted so we could enable TS strict mode
        handleUpdateNotifications(currentData, notification, 'created'),
      )
    }

    const socketInstance = socket.current

    socketInstance.on('updated', onUpdated)
    socketInstance.on('created', onCreated)

    return () => {
      socketInstance.off('updated', onUpdated)
      socketInstance.off('created', onCreated)
    }
  }, [handleNotification, queryClient, queryKey])

  useEffect(() => {
    if (!auth) {
      return
    }

    const socketInstance = socket.current

    // Obtains the user token and manually connects the WebSocket
    auth.getTokenString().then((token) => {
      if (!token) {
        return
      }

      socketInstance.io.opts.query = {
        token,
      }

      socketInstance.connect()
    })

    return () => {
      if (socketInstance.connected) {
        socketInstance.disconnect()
      }
    }
  }, [auth])
}

export default useNotificationSocket
