import config from '../common/config'
import { handleErrors } from '../common'

export async function getTags() {
  const url = config.url.api('/analysis-tags/')
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'GET',
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}
