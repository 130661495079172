import { useOpointUser } from '@opoint/authjs-react'
import { useMemo } from 'react'
import { M360Article } from '../../../../../opoint/articles/types'
import { getAllTagLikeEntities } from '../../../../../selectors/tagsComposedSelectors'
import ModulePermissions from '../../../../common/ModulePermissions'
import { MODULES } from '../../../../constants/permissions'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import ArticleTags from '../../../../tags/Tag'
import { isAllTagsHidden } from '../../../../../selectors/settingsSelectors'
import { getFoldersTree } from '../../../../../selectors/foldersSelectors'
import { shouldShowTag } from '../../../../../helpers/tags'
import { Tag } from '../../../../types/tag'
import useIsUserPermitted from '../../../../hooks/useIsUserPermitted'

type ArticleFooterProps = {
  article: M360Article
  originalArticle: M360Article
  breakToPopup?: boolean
}

const ArticleFooter = (props: ArticleFooterProps) => {
  const { article, originalArticle, breakToPopup = true } = props

  const tagsFn = useAppSelector(getAllTagLikeEntities)
  const hideAllTags = useAppSelector(isAllTagsHidden)
  const folders = useAppSelector(getFoldersTree)

  const isPermittedToCategorize = useIsUserPermitted({
    module: 'ENABLE_CATEGORIZATION',
    permissions: [MODULES.ENABLE_CATEGORIZATION.AS_LIST],
  })

  const tags = hideAllTags ? [] : (tagsFn(article) as Tag[])
  const user = useOpointUser()
  const ids = useMemo(() => [...new Set(tags.map((tag) => tag.folder).filter(Boolean))], [tags])

  const filteredFolders = folders.filter((f) => ids.some((id) => id === f.id))
  // @ts-expect-error: Incorrect schema
  const mergedChildren = filteredFolders.reduce((acc, obj) => acc.concat(obj.children), [])
  const articleIdString = `id:${article.id_site}_${article.id_article}`

  return (
    // TODO Consider renaming article and activeArticle so they match
    <>
      <div className="text-label-s font-bold text-sky.cloudy">
        {user?.is_staff || user?.impersonator?.is_staff ? (
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://tools.opoint.com/AI/art_info.php?id_site=${article.id_site}&id_article=${article.id_article}`}
          >
            {articleIdString}
          </a>
        ) : (
          <span>{articleIdString}</span>
        )}
      </div>

      <div className="relative items-center pt-6 font-sans">
        {/* Article tags */}
        <ModulePermissions module="TAG_MODULE" permissions={[MODULES.TAG_MODULE.ON]}>
          <ArticleTags
            article={originalArticle || article}
            tags={isPermittedToCategorize ? mergedChildren : tags?.filter((tag) => shouldShowTag(tag))}
            /* TODO: FIND OUT IF THIS SHOULD CONTAIN SOMETHING ELSE */
            tagsContainer={breakToPopup ? 'articleFooter' : 'uploadArticleFooter'}
          />
        </ModulePermissions>
      </div>
    </>
  )
}

export default ArticleFooter
