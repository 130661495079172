import { FeedFormData } from '../../components/feeds/FeedForm'
import { isTag } from '../../guards/tags'
import config from '../common/config'
import type { Feed, Filter } from '../flow'
import { handleErrors } from '../common'

/**
 * API Functions
 */
export const getFeeds = async (): Promise<Array<Feed>> => {
  const url = config.url.api('/feeds/')
  const request = new Request(url, { ...(await config.request.getRequestHeaders()), method: 'GET' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const getFeed = async (feedId: string): Promise<Feed> => {
  const url = config.url.api(`/feeds/${feedId}/`)
  const request = new Request(url, { ...(await config.request.getRequestHeaders()), method: 'GET' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const updateFeed = async (feedId: string, updatedData: FeedRequestData): Promise<Array<Feed>> => {
  const url = config.url.api(`/feeds/${feedId}/`)
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'PUT',
    body: JSON.stringify(updatedData),
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export function transformFeedFormDataToFeedRequestData(feedFormData: FeedFormData): FeedRequestData {
  return {
    name: feedFormData.feedName,
    feedType: feedFormData.type,
    articlesCount: feedFormData.articleCount,
    xsltFile: null,
    description: feedFormData.feedDescription,
    mifluzIdLists: feedFormData.content.map((value) => parseInt(value, 10)),
    identicalArticles: feedFormData.includeIdenticalArticles,
    feedSettings: feedFormData.feedSettings,
  }
}

type FeedSettings = {
  alternativeSource: boolean
  images: boolean
  includeContext: boolean
  neverRedir: boolean
  source: boolean
  sourceTitle: boolean
  privateLink: boolean
  useDefaultAccessGroups: boolean
}

export type FeedRequestData = {
  name: string
  feedType: string | null
  articlesCount: number | null
  xsltFile?: string | null
  description: string
  mifluzIdLists: number[]
  identicalArticles: boolean
  feedSettings?: FeedSettings | null
}

export const createFeed = async (newFeed: FeedRequestData): Promise<Feed> => {
  const url = config.url.api('/feeds/')
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'POST',
    body: JSON.stringify(newFeed),
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const deleteFeed = async (feedId: string): Promise<void> => {
  const url = config.url.api(`/feeds/${feedId}/`)
  const request = new Request(url, { ...(await config.request.getRequestHeaders()), method: 'DELETE' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.text())
}

export const getActiveProfileTagList = (
  profileTags: Array<{ id: number; name: string }>,
  activeIds: Array<number>,
): Filter[] => {
  if (!profileTags || !profileTags.length || !activeIds || !activeIds.length) {
    return []
  }

  const filtered = profileTags.filter((profileTag) => {
    return activeIds.indexOf(profileTag.id) !== -1
  })

  return filtered.map((item) => ({ id: item.id, type: isTag(item) ? 'tag' : 'profile', name: item.name }))
}
