import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { getStatisticViewData } from '../../../../opoint/statistics'
import { useAppDispatch } from '../../../hooks/useAppDispatch'

const useHandleStatisticsSelection = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const navigateToStatistics = useCallback(
    async (statisticId: string | number) => {
      const newStatisticsId = Number(statisticId)
      try {
        const savedStatistic = await getStatisticViewData(newStatisticsId)
        const isComparisonEnabled = savedStatistic.dashboard.search.params.isComparisonEnabled

        if (isComparisonEnabled) {
          dispatch({ type: 'GO_TO_STATISTICS_COMPARISON' })

          navigate(`/statistics/${newStatisticsId}/compare/`)
        } else {
          navigate(`/statistics/${newStatisticsId}`)
        }
      } catch (error) {
        console.error('Error fetching saved statistics:', { error })
        // Fall back to the statistics view
        navigate(`/statistics/${newStatisticsId}`)
      }
    },
    [dispatch, navigate],
  )

  return { navigateToStatistics }
}

export default useHandleStatisticsSelection
