import ReactDOM from 'react-dom'

import { getArticleId } from '@opoint/infomedia-storybook'
import { assoc, compose, eqBy, evolve, ifElse, indexBy, map, prop } from 'ramda'
import { articleWithCorrectedImagePaths } from '../../components/helpers/article'
import type { ECBContent } from '../../components/types/article'
import config from '../common/config'
import { OpointTimestampToTimestamp } from '../common/time'
import type { AddArticleSuggestionMediaType, EnhancedArticleData } from '../flow'
import { handleErrors } from '../common'
import { M360Article } from './types'

export const TYPE_PREVIEW = 'preview'
export const TYPE_LISTING = 'listing'

export const articleIdFromIds: (id_site: number, id_article: number) => string = (id_site, id_article) => {
  return `${id_site}_${id_article}`
}

/**
 * Given an article, this function returns all articles (incl. identical articles)
 * @sig M360Article -> Array<M360Article>
 */
export const getAllIdenticalArticles = ifElse(
  (article) => article.identical_documents && article.identical_documents.document.length,
  (article) => article.identical_documents.document,
  (article) => [article],
)

/**
 * @return list of articleId of all identical articles of given article
 */
export const articleIdenticalIds = (article: M360Article) => {
  return getAllIdenticalArticles(article)?.map(getArticleId)
}

export const eqArticles = eqBy(getArticleId)

export const articleIdToArticle: (articleId: string) => { id_site: string; id_article: string } = (articleId) => {
  const [id_site, id_article] = articleId.split('_')

  return { id_site, id_article }
}

/**
 * @return active identical article or null if no identical articles are available
 */
export const getIdenticalArticle = (identical: Record<string, number>, article: M360Article): M360Article | null => {
  const index = identical[getArticleId(article)]

  if (isNaN(index) || !article.identical_documents) {
    return null
  }

  return article.identical_documents.document[index] as M360Article
}

export const ARTICLE_NORMAL = 0
export const ARTICLE_LOGIN = 1
export const ARTICLE_LOGIN_DOWNLOAD = 2
export const ARTICLE_NORMAL_SEPARATE_VIP = 3
export const ARTICLE_GALLERY = 4
export const ARTICLE_NOT_OLE = 5
export const ARTICLE_NOT_OLE_REDIR = 6
export const ARTICLES_BEFORE_END_TO_AUTOLOAD = 5
export const MAX_AUTOLOADED_ARTICLES = 50

/* eslint-disable-next-line max-len */
export const urlRegExp =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]?[a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]?[a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/

export const getArticleUrl = ({
  content_protected,
  orig_url,
  url,
}: {
  content_protected?: number
  orig_url?: string
  url?: string
}) => {
  /**
   * @example orig_url: "http://kulturistika.ronnie.cz/c-25432-na-balaton-open-throwdown-2016-zavodili-i-cesi.html"
   * @example url: "http://redir.opoint.com/?key=ajiIFdAFBziAtzMZ1FoM"
   *
   * We return orig_url in case article is public or navigate to
   * redir page in case it's protected.
   * if content_protected is not from the interval [0,6]m return url
   *
   * @see https://trello.com/c/H3MyjB21/1274-articles-behind-paywalls-is-being-visible-to-the-users-the-wrong-way
   * @see https://trello.com/c/9EnBJiRG/1584-pay-wall-we-read-behind-the-wall-in-m3-but-not-in-app
   *
   */

  const isOrigUrlUsed = (contentProtected?: number) => {
    switch (contentProtected) {
      case ARTICLE_LOGIN:
      case ARTICLE_LOGIN_DOWNLOAD:
      case ARTICLE_NOT_OLE_REDIR:
        return false
      case ARTICLE_NORMAL:
      case ARTICLE_NORMAL_SEPARATE_VIP:
      case ARTICLE_GALLERY:
      case ARTICLE_NOT_OLE:
        return true
      default:
        return true
    }
  }

  return isOrigUrlUsed(content_protected) ? orig_url : url
}

/**
 * Function return primary and secondary url for article headline
 * for translated article urls are swapped
 *
 * @param {String} GTranslate_url   URL for the translated article
 * @param {Integer} content_protected   Is the content protected
 * @param {string} orig_url   Original URL for the article
 * @param {Boolean} translated   Should the text be translated
 * @param {String} url   URL for the article
 * @returns {{ primary: string, secondary: string}}
 */
export const getHeaderUrls = ({
  GTranslate_url,
  content_protected,
  orig_url,
  translated,
  url,
}: {
  GTranslate_url?: string
  content_protected?: number
  orig_url?: string
  translated?: boolean | 0 | 1
  url?: string
}) => {
  const origUrl = getArticleUrl({ content_protected, orig_url, url })
  const gtUrl = GTranslate_url

  return translated ? { primary: gtUrl, secondary: origUrl } : { primary: origUrl, secondary: gtUrl }
}

/**
 * Function return language iso codes
 * original and destination if translated
 *
 * @param {String} GTranslate_url   URL for the translated article
 * @param {Object} language   Contains the name of the language to translate to
 * @param {Object} orig_language   Contains the name of the original language
 * @param {Boolean} translated   Should the text be translated
 * @returns {{orig: string, dest:? string}}
 */
export const getLangCodes = ({
  GTranslate_url,
  language,
  orig_language,
  translated,
}: {
  GTranslate_url?: string
  language: { text: string }
  orig_language: { text: string }
  translated?: boolean
}) => {
  if (translated) {
    return {
      orig: orig_language?.text,
      dest: language.text,
    }
  }

  return {
    orig: language.text,
    // @ts-expect-error: Muted so we could enable TS strict mode
    dest: GTranslate_url ? GTranslate_url.match(/tl=([a-z]{2})/)[1] : null,
  }
}

/**
 * Funtion returns node reference of specific article from Array of references
 * @param  {Array} articlesRefs   Array of article refs
 * @param  {M360Article} article      M360Article object
 * @return {Node}                 Node reference for specific article
 */
export const getActiveArticleNode = (articlesRefs, article) => {
  // eslint-disable-next-line react/no-find-dom-node
  return ReactDOM.findDOMNode(articlesRefs[getArticleId(article)])
}

export const suggestionMediaTypesIntoSuggestionShape = map(
  ({ id_site: idSite, type, sitename }: AddArticleSuggestionMediaType) => ({
    id: idSite,
    name: `${type}: ${sitename}`,
    expandedMediatype: true,
  }),
)

export const addArticle = async (
  newArticle,
  access: 'customer' | 'global' | 'user',
): Promise<{
  id_site: number
  id_article: number
}> => {
  const url = config.url.api('/articles/create/')
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'POST',
    body: JSON.stringify({ ...newArticle, access }),
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

// used in Add M360Article file upload
export const uploadArticleFile = async (file: FormData) => {
  // Removing content-type property from headers. This way
  // browser can set this header by itself. It is easiest way how to set correct
  // boundary for file upload to work correct
  const headers = await config.request.getRequestHeaders()
  delete headers['content-type']

  const url = config.url.api('/articles/upload/')
  const request = new Request(url, {
    ...headers,
    method: 'POST',
    body: file,
  })

  return fetch(request)
    .then(handleErrors)
    .then(({ response }) => response)
}

/**
 * Index tags by id
 * @param article
 */
export const indexTagsById = (article: M360Article): M360Article =>
  evolve({
    // @ts-expect-error: Muted so we could enable TS strict mode
    tags: indexBy(prop('id')),
  })(article)

/**
 * Create unique id of document and his own identical documents
 * @param article
 */
const indexBySiteAndArticleId = (article: M360Article): M360Article =>
  // @ts-expect-error: Muted so we could enable TS strict mode
  compose(
    assoc('id', getArticleId(article)),
    evolve({
      identical_documents: {
        document: map(indexBySiteAndArticleId),
      },
    }),
  )(article)

const normalizeTagsTimestamps = evolve({
  tags: map(
    evolve({
      sent: (timestamp) => OpointTimestampToTimestamp(timestamp) * 1000,
      equal_sent: (timestamp) => OpointTimestampToTimestamp(timestamp) * 1000,
    }),
  ),
})

/**
 * Function copies input article to 1st position in
 * identical_documents.document array but without identical_documents prop
 * @param {M360Article} article - article to process
 * @return M360Article
 */
const removeIdentical = (article: M360Article) => {
  if (article.identical_documents?.cnt === 0) {
    return article
  }

  const { identical_documents: _keyToRemove, ...articleWithoutIdenticalDocuments } = article

  return {
    ...article,
    identical_documents: {
      ...article.identical_documents,
      // @ts-expect-error: Muted so we could enable TS strict mode
      document: [articleWithoutIdenticalDocuments, ...article.identical_documents?.document],
    },
  }
}

/**
 * @sig Array<M360Article> -> Array<M360Article>
 */

// @ts-expect-error: Muted so we could enable TS strict mode
export const preprocessArticle = compose(
  removeIdentical,
  evolve({
    identical_documents: {
      document: map(indexTagsById),
    },
  }),
  indexTagsById,
  normalizeTagsTimestamps,
  indexBySiteAndArticleId,
  articleWithCorrectedImagePaths,
)

export const preprocessArticles = compose(map(preprocessArticle))

/**
 * Reducer to merge current identical object with new properties
 * @param  {Object} acc      identical object with indentical articles indexes
 * @param  {M360Article} article article to iterate over
 * @return {Object}          merged identical object
 */
export const identicalReducer = (acc, article) => {
  const articleId = getArticleId(article)

  if (Object.keys(acc).includes(articleId)) {
    return acc
  }

  if ((article?.identical_documents?.document || []).length > 0) {
    return { ...acc, [articleId]: 0 }
  }

  return acc
}

/**
 * Updates article using update metadata provided
 */
export const updateArticle = async (article: M360Article, updatedArticleData: EnhancedArticleData): Promise<any> => {
  const { id_site, id_article } = article

  const updatedArticle = {
    body: updatedArticleData.body && updatedArticleData.body.text,
    summary: updatedArticleData.summary && updatedArticleData.summary.text,
    header: updatedArticleData.header && updatedArticleData.header.text,
    author: updatedArticleData.author,
    translated_header: updatedArticleData.translated_header,
    manual_summary: updatedArticleData.manual_summary,
  }

  const body = {
    id_site,
    id_article,
    ...updatedArticle,
  }

  const url = config.url.api('/articles/edited/')
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'POST',
    body: JSON.stringify(body),
  })

  return fetch(request)
    .then(handleErrors)
    .then(() => ({
      ...article,
      ...updatedArticleData,
    }))
}

export const shareArticles = async (
  articles: Array<M360Article>,
  message: string,
  recipients: Array<any>,
  shareAttachment: boolean,
  title = 'Shared articles',
  templateId = 11,
) => {
  const body = {
    articles,
    message,
    recipients,
    shareAttachment,
    templateId,
    title,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }

  const url = config.url.api('/articles/share/')
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'POST',
    body: JSON.stringify(body),
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const getGroupsOfIdMediaTypes = async (sitesIds: string) => {
  const url = config.url.api(`/site_search/site_type/?sites=${sitesIds}`)
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'GET',
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export enum LogActions {
  ArticleConsumed = 2,
  ArticleConsumed3Seconds = 3,
  ArticleConsumed10Seconds = 4,
  ArticleConsumedClick = 5,
  PDFViewed = 6,
  MediaClipPlayed = 7,
  ImageClicked = 8,
  ArticleAddedToTag = 9, //TODO: This one hasn't been implemented yet
}

/**
 * Sends information about article read
 * @param article - article that was read
 * @param action - action that needs to be logged
 * @returns void
 */
export const logArticleAction = async ({
  id_site,
  id_article,
  action,
}: {
  id_site: number
  id_article: number
  action: LogActions[]
}): Promise<any> => {
  if (!id_article || !id_site) {
    return null
  }

  const url = config.url.api('/articles/log_visited/')
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'POST',
    body: JSON.stringify({ id_site, id_article, action }),
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

/**
 * Updates article headline or manual summary
 */
export const updateECBArticle = async (article: M360Article, updatedArticleData: ECBContent): Promise<any> => {
  const { id_site, id_article } = article

  const url = config.url.api(`/articles/${id_site}/${id_article}/`)
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'PATCH',
    body: JSON.stringify(updatedArticleData),
  })

  return fetch(request)
    .then(handleErrors)
    .then(() => ({
      ...article,
      ...updatedArticleData,
    }))
}
