import { ButtonHTMLAttributes, forwardRef, MouseEvent } from 'react'
import { DataTreemap24Regular } from '@fluentui/react-icons'
import { useTranslation } from 'react-i18next'
import { getNewPortalDashboardName, getNewPortalDashboardURL } from '../../../../selectors/settingsSelectors'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { NavigationSection, NavigationSectionHeaderButton } from '../primitives/NavigationSection'

export const DashboardsNavigationSection = forwardRef<
  HTMLButtonElement,
  ({ isExpanded?: boolean } & ButtonHTMLAttributes<HTMLButtonElement>) | Record<string, never>
>(({ isExpanded, ...props }, ref) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const dashboardName = useAppSelector(getNewPortalDashboardName)
  const dashboardURL = useAppSelector(getNewPortalDashboardURL)

  const handleDashboardClick = (event: MouseEvent<HTMLButtonElement>) => {
    dispatch({
      type: 'TOGGLE_DASHBOARD_MODAL',
      // this component is only mounted if !!dashboardURL
      payload: { url: dashboardURL!, name: dashboardName ?? null, isOpen: true },
    })

    if (typeof props.onClick === 'function') {
      props.onClick(event)
    }
  }

  return (
    <NavigationSection>
      <NavigationSectionHeaderButton
        {...props}
        isExpanded={isExpanded}
        label={t('Dashboard')}
        ref={ref}
        onClick={handleDashboardClick}
        data-cy="DashboardsNavigationSection-button"
      >
        <DataTreemap24Regular className="w-7 shrink-0  text-sky.4" />
      </NavigationSectionHeaderButton>{' '}
    </NavigationSection>
  )
})
DashboardsNavigationSection.displayName = 'DashboardsNavigationSection'
