import { captureMessage } from '@sentry/react'

import { getArticleId } from '@opoint/infomedia-storybook'
import { produce } from 'immer'
import { AppActions } from '../actions'
import {
  ReportArticle,
  ReportGroup,
  ReportObject,
  ReportsTagHistory,
  ReportWithNewTimesTemp,
  Source,
} from '../components/types/reports'
import { keyBy } from '../helpers/common'
import { articleIdenticalIds, eqArticles, preprocessArticle } from '../opoint/articles'
import { REPORT_STATUS, REPORT_STEP_DOWNLOAD_SHARE } from '../opoint/reports'

export type ReportsState = {
  autoTranslate: boolean
  backup: {
    // there will be preserved metadata of unfished report
    order?: { [weight: string]: Array<Array<string>> } // nested Array is for ids of identicals
    toDelete?: { [weight: string]: Array<Array<string>> } // nested Array is for ids of identicals
  }
  compactListing: boolean
  contentFilter?: string
  createReportIsTakingTooLong: boolean
  dateTimeEnd?: Date
  dateTimeStart?: Date
  filterMode: 'filter' | 'highlight'
  footer: string
  preface: string
  reportsHistory?: Record<number, ReportsTagHistory | ReportWithNewTimesTemp>
  reportId: number | null
  reportObject?: ReportObject // report object from API todo
  search: {
    articles?: {
      [key: string]: Array<ReportArticle>
    }
    context?: string
    count: number
    groups?: Record<number, ReportGroup>
    loading: boolean
  }
  shareReportData: {
    attachment: boolean
    shareReportMessage: string
    stepNumber?: number
  }
  showDeleted: boolean
  source?: Source
  step: number
  template?: number
  title: string
  updateSoMe: boolean
  useReportHistory: boolean
}

export const initialState: ReportsState = {
  autoTranslate: false,
  backup: {},
  compactListing: false,
  createReportIsTakingTooLong: false,
  filterMode: 'filter',
  footer: '',
  preface: '',
  reportId: null,
  search: {
    count: 0,
    loading: false,
  },
  shareReportData: {
    attachment: false,
    shareReportMessage: '',
  },
  showDeleted: false,
  step: 0,
  title: '',
  updateSoMe: false,
  useReportHistory: false,
}

const reportsReducer = produce((draftState, action: AppActions) => {
  switch (action.type) {
    case 'REPORTS_RESET': {
      if (!action.payload.soft || !draftState.search.articles) {
        return initialState
      }
      // backup metadata if modal is closed before generating report (soft reset)
      // to persist ordering and `deleted` marking

      // return 3 dimensional array: group > article > identicalArticle

      const toIdsLists = (articles: { [key: string]: ReportArticle[] } = {}) => {
        return Object.fromEntries(
          Object.entries(articles).map(([key, value]) => [
            key,
            value.map(({ document }) => articleIdenticalIds(document)),
          ]),
        )
      }

      const toIdsListsDeleted = (articles: { [key: string]: ReportArticle[] } = {}) => {
        return Object.fromEntries(
          Object.entries(articles).map(([key, value]) => [
            key,
            value.filter((article) => article.deleted).map(({ document }) => articleIdenticalIds(document)),
          ]),
        )
      }

      return {
        ...initialState,
        showDeleted: draftState.showDeleted,
        backup: {
          ...initialState.backup,
          order: toIdsLists(draftState.search.articles),
          toDelete: toIdsListsDeleted(draftState.search.articles),
        },
      }
    }
    case 'REPORTS_TEMPLATE': {
      const { templateId } = action.payload

      draftState.template = templateId
      break
    }
    case 'REPORTS_SOURCE': {
      draftState.source = action.payload
      draftState.search.articles = undefined

      break
    }
    case 'REPORTS_DATE_START': {
      draftState.dateTimeStart = action.payload
      draftState.useReportHistory = false
      draftState.search.articles = undefined

      break
    }
    case 'REPORTS_DATE_END': {
      draftState.search.articles = undefined
      draftState.dateTimeEnd = action.payload
      draftState.useReportHistory = false

      break
    }
    case 'REPORTS_REPORT_USE_HISTORY': {
      draftState.search.articles = undefined
      draftState.useReportHistory = action.payload

      break
    }
    case 'REPORTS_CHANGE_AUTO_TRANSLATE': {
      draftState.search.articles = undefined
      draftState.autoTranslate = action.payload

      break
    }
    case 'REPORTS_ARTICLES': {
      draftState.search.loading = true
      break
    }

    case 'REPORTS_LOAD_INITIAL_ARTICLES': {
      draftState.search.articles = undefined

      break
    }

    case 'REPORTS_ARTICLES_SUCCESS': {
      const {
        searchresult: {
          context = null,
          document = [],
          sort_groups = [{ name: 'Global', group: -1, weight: undefined }],
          count,
          range_count,
        },
        alreadyPreprocessed = false,
      } = action.payload

      const groups = sort_groups
        //@ts-expect-error this key is missing
        .filter(({ hidden }) => !hidden) // Filter out hidden groups
        .reduce((acc, group) => {
          //@ts-expect-error this key is missing
          acc[group.weight] = group // Index by weight
          return acc
        }, {})
      // Function to determine groupId
      const groupId = ({ groupId: id }) => (groups[id] ? id : undefined)

      const preprocessedDocuments = alreadyPreprocessed
        ? document
        : document.map((a) => {
            const updatedDocument = { ...a }
            updatedDocument.document = preprocessArticle(updatedDocument.document)
            return updatedDocument
          })

      let articles = preprocessedDocuments.reduce((acc, item) => {
        const key = groupId(item)
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(item)
        return acc
      }, {})

      if (draftState.backup) {
        // apply saved `delete` marks
        const isMarked = (weight, id) => {
          const marksOfGroup = draftState.backup.toDelete?.[weight] || []

          return marksOfGroup.some((mark) => mark.includes(id))
        }

        articles = Object.keys(articles).reduce((acc, weight) => {
          acc[weight] = articles[weight].map((item) => ({
            ...item,
            ...(isMarked(weight, getArticleId(item.document)) ? { deleted: true } : {}),
          }))
          return acc
        }, {})

        // apply saved order
        const articlePosition = (weight, id) => {
          const orderOfGroup = draftState.backup.order?.[weight] || []

          return orderOfGroup.findIndex((item) => item.includes(id))
        }

        articles = Object.keys(articles).reduce((acc, weight) => {
          acc[weight] = articles[weight].sort(
            (a, b) =>
              articlePosition(weight, getArticleId(a.document)) - articlePosition(weight, getArticleId(b.document)),
          )
          return acc
        }, {})
      }

      const mergeWithConcat = (obj1, obj2) => {
        const result = { ...obj1 } // Start with a shallow copy of obj1

        for (const key of Object.keys(obj2)) {
          if (key in result) {
            // If the key exists in both objects, concatenate the arrays
            result[key] = [...(result[key] || []), ...(obj2[key] || [])]
          } else {
            // If the key only exists in obj2, add it to the result
            result[key] = obj2[key]
          }
        }

        return result
      }

      draftState.search.loading = false
      draftState.search.articles = draftState.search.articles
        ? mergeWithConcat(draftState.search.articles || {}, articles)
        : articles
      draftState.search.context = context || undefined
      draftState.search.groups = !draftState.search.groups ? groups : { ...draftState.search.groups, ...groups }
      draftState.search.count = range_count ? Math.max(count, range_count) : count

      break
    }
    case 'REPORTS_ARTICLE_SORT': {
      const { weight, sourceIndex, targetIndex } = action.payload

      const articles = draftState.search.articles?.[weight]
      const sourceElement = articles?.[sourceIndex]

      if (articles && sourceElement) {
        articles.splice(sourceIndex, 1)
        articles.splice(targetIndex, 0, sourceElement)

        if (draftState.search.articles) {
          draftState.search.articles[weight] = articles
        }
      }

      break
    }
    case 'REPORTS_ARTICLE_MARK_TO_DELETE': {
      const { article } = action.payload

      const articles = draftState.search.articles ?? {}

      draftState.search.articles = Object.keys(articles).reduce((acc, weight) => {
        acc[weight] = articles[weight].map((item) => {
          if (eqArticles(article, item.document)) {
            return {
              ...item,
              deleted: !item.deleted,
            }
          }

          return item
        })
        return acc
      }, {})

      break
    }
    case 'REPORTS_ARTICLES_FAILURE': {
      draftState.search.articles = {}
      draftState.search.loading = false

      break
    }

    case 'REPORTS_FILTER_MODE': {
      if (typeof action.payload !== 'string' || !['filter', 'highlight'].includes(action.payload)) {
        throw new Error('Wrong action.payload')
      }

      draftState.filterMode = action.payload

      break
    }
    case 'REPORTS_COMPACT_LISTING_TOGGLE': {
      draftState.compactListing = action.payload

      break
    }
    case 'REPORTS_SHOW_DELETED_TOGGLE': {
      draftState.showDeleted = !draftState.showDeleted

      break
    }
    case 'REPORTS_HISTORY_FETCH': {
      draftState.reportsHistory = []

      break
    }

    case 'REPORTS_HISTORY_FETCH_SUCCESS': {
      const { historyList } = action.payload

      if (!historyList) {
        captureMessage('Reports history is not defined', { level: 'info' })
      }

      const reportsHistory = historyList ?? []

      const preselectedHistory = reportsHistory.map((item) => {
        if (item.timestamp === '0') {
          return { ...item, selected: true }
        }

        return item
      })

      if (preselectedHistory.every((item) => !item.selected) && preselectedHistory.length > 0) {
        preselectedHistory[0].selected = true
      }

      const useReportHistory = preselectedHistory?.some((item) => item.selected)

      const historyIndexedByStimestamps = keyBy(preselectedHistory, (item) => item.stimestampUsed)

      draftState.reportsHistory = historyIndexedByStimestamps
      draftState.useReportHistory = useReportHistory

      break
    }
    case 'REPORTS_HISTORY_CHECK_TOGGLE': {
      const { id } = action.payload || {}

      draftState.search.articles = undefined
      draftState.useReportHistory = true

      if (draftState.reportsHistory) {
        draftState.reportsHistory[id].selected = !draftState.reportsHistory[id].selected
      }

      break
    }
    case 'REPORTS_SORT_TAG_SUCCESS': {
      // ? When refactoring this reducer, fix state.search.articles
      // ? The shape of the data is way off, array of articles is keyed by `undefined`
      // ! Keeping the current shape as it would require a lot of changes in this reducer
      const articles = draftState.search.articles?.['undefined'] ?? []

      const articlesToBeDeletedIds = articles
        .filter((article) => article.deleted)
        .map((article) => articleIdenticalIds(article.document))

      draftState.search.articles = undefined
      draftState.backup = {
        order: undefined,
        toDelete: {
          // ? When refactoring this reducer, fix state.backup.toDelete
          // ? The shape of the data is way off, array of articles is keyed by `undefined`
          // ! Keeping the current shape as it would require a lot of changes in this reducer
          undefined: articlesToBeDeletedIds,
        },
      }

      break
    }
    // backup metadata if modal is closed before generating report (soft reset)
    case 'REPORTS_STEP_SUCCESS': {
      const { step } = action.payload

      draftState.step = step

      break
    }
    case 'REPORTS_TITLE': {
      const { title } = action.payload

      draftState.title = title
      break
    }
    case 'REPORTS_PREFACE': {
      const { preface } = action.payload

      draftState.preface = preface

      break
    }
    case 'REPORTS_FOOTER': {
      const { footer } = action.payload

      draftState.footer = footer

      break
    }
    case 'REPORTS_UPDATE_SOME_META': {
      const { updateSoMe } = action.payload

      draftState.updateSoMe = updateSoMe

      break
    }
    case 'UPDATED_SOME_META_DATA_FAILURE': {
      // @ts-expect-error - incorrect reportObject schema
      draftState.reportObject = { ...draftState.reportObject, error: REPORT_STATUS.UPDATE_ERROR }

      break
    }
    case 'REPORTS_UPDATE_REPORT_ID': {
      const { reportId } = action.payload

      draftState.reportId = reportId

      break
    }
    case 'REPORTS_CREATE': {
      draftState.createReportIsTakingTooLong = false
      // @ts-expect-error - incorrect reportObject schema
      draftState.reportObject = { ...draftState.reportObject, status: REPORT_STATUS.IN_PROGRESS }

      break
    }

    case 'REPORTS_CREATE_IS_TAKING_TOO_LONG': {
      draftState.createReportIsTakingTooLong = true

      break
    }

    case 'REPORTS_CREATE_SUCCESS': {
      const { reportObject } = action.payload

      draftState.reportObject = reportObject
      draftState.step = REPORT_STEP_DOWNLOAD_SHARE
      draftState.backup = initialState.backup
      draftState.reportId = null

      break
    }
    case 'REPORTS_CREATE_FAILURE': {
      if (draftState.reportObject) {
        draftState.reportObject.status = REPORT_STATUS.ERROR
      }

      draftState.step = REPORT_STEP_DOWNLOAD_SHARE
      draftState.backup = initialState.backup
      draftState.reportId = null

      break
    }

    case 'EDIT_ARTICLE_SUCCESS': {
      const { article } = action.payload

      draftState.search.articles = draftState.search.articles
        ? Object.fromEntries(
            Object.entries(draftState.search.articles ?? {}).map(([key, group]) => [
              key,
              group.map((item) => {
                // Check if the document matches the article and update it if it does
                if (eqArticles(article, item.document)) {
                  return {
                    ...item,
                    document: article, // Update the document
                  }
                }
                return item // Return original item if not matched
              }),
            ]),
          )
        : draftState.search.articles

      break
    }
    case 'SHARE_REPORT_CHANGE_MESSAGE': {
      const { message } = action.payload

      draftState.shareReportData.shareReportMessage = message

      break
    }
    case 'SHARE_REPORT_UPDATE_STEP': {
      const { stepNumber } = action.payload

      draftState.shareReportData.stepNumber = stepNumber

      break
    }
    case 'SHARE_REPORT_TOGGLE_ATTACHMENT': {
      draftState.shareReportData.attachment = !draftState.shareReportData.attachment

      break
    }

    case 'CLEAR_SHARE_REPORT_DATA': {
      draftState.shareReportData.attachment = false
      draftState.shareReportData.shareReportMessage = ''

      break
    }

    default:
      return draftState
  }
}, initialState)

export default reportsReducer
