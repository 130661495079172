import {
  Button,
  cn,
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  formatDate,
  Input,
  InputGroup,
  InputRightElement,
  MultiFilterDropdown,
  MultiFilterDropdownContent,
  MultiFilterDropdownItem,
  MultiFilterDropdownTrigger,
  SingleFilterDropdownOption,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@opoint/infomedia-storybook'
import { Trans, useTranslation } from 'react-i18next'

import {
  Dismiss20Regular,
  Mail20Regular,
  Megaphone20Regular,
  Person20Regular,
  QuestionCircle24Regular,
  Search20Regular,
} from '@fluentui/react-icons'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  ColumnFiltersState,
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { GroupRecipientChild, MailSentStatus } from '../../../api/opoint.schemas'
import { useAlertsRecipientsList } from '../../../api/alerts/alerts'
import { useAppSelector } from '../../hooks/useAppSelector'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { getAlertRecipientsListOpen } from '../../../selectors/uiSelectors'
import { getCurrentAlertHistoryItem } from '../../../selectors/alertsSelectors'
import { getOpointLocale } from '../../../selectors/settingsSelectors'
import { OpointTimestampToTimestamp } from '../../../opoint/common/time'
import { filterOptionsByQuery } from '../../../helpers/actionBar'
import useIsUserPermitted from '../../hooks/useIsUserPermitted'
import { MODULES } from '../../constants/permissions'
import {
  getGroupRecipientWithStatus,
  getRecipientEmail,
  handleSearchFilter,
  isGroupRecipient,
  isGroupRecipientChild,
  statusSortingFunction,
} from './helpers'
import RecipientDeleteConfirmationModal from './RecipientDeleteConfirmationModal'
import RecipientTable from './RecipientTable'
import { RecipientNameCellData, RecipientType, RecipientWithGroupStatus } from './types'
import RecipientRemoveButton from './RecipientRemoveButton'
import RecipientName from './RecipientName'
import StatusTag from './StatusTag'

const columnHelper = createColumnHelper<RecipientWithGroupStatus | GroupRecipientChild>()

const RecipientsListModal = () => {
  const { t } = useTranslation()

  const hasAlertPermission = useIsUserPermitted({
    module: 'ALERT_MODULE',
    permissions: [MODULES.ALERT_MODULE.ON],
  })

  const RECIPIENT_OPTIONS: { value: RecipientType; label: string }[] = [
    { value: 'email', label: t('Email') },
    { value: 'mobile', label: t('Mobile') },
    { value: 'contact', label: t('Contact') },
    { value: 'user', label: t('User') },
    { value: 'group', label: t('Group') },
  ]

  const MAIL_DELIVERY_CATEGORY_OPTIONS = [
    { value: MailSentStatus.Delivered, label: t('Delivered') },
    { value: MailSentStatus.Processing, label: t('Processing') },
    { value: MailSentStatus.Failed, label: t('Not delivered') },
    { value: MailSentStatus.Filtered, label: t('Not sent') },
    { value: MailSentStatus.Unknown, label: t('Unknown') },
  ]

  const { alertId, lastSent, alertName, open } = useAppSelector(getAlertRecipientsListOpen)
  const currentHistoryItem = useAppSelector(getCurrentAlertHistoryItem)
  const locale = useAppSelector(getOpointLocale)
  const { mailLogId } = useParams()

  const logId = mailLogId ?? 'next'

  const [selectedTypes, setSelectedTypes] = useState<SingleFilterDropdownOption[]>([])
  const [selectedStatus, setSelectedStatus] = useState<SingleFilterDropdownOption[]>([])
  const [searchFilter, setSearchFilter] = useState('')
  const [typeQuery, setTypeQuery] = useState('')
  const [statusQuery, setStatusQuery] = useState('')

  const dispatch = useAppDispatch()
  const { data, status } = useAlertsRecipientsList(
    Number(alertId),
    {
      ...(logId !== 'next' ? { id_maillog: logId } : {}),
    },
    {
      query: {
        enabled: !!alertId,
      },
    },
  )

  const recipientsWithGroupStatus = useMemo(() => {
    return (
      data?.map((recipient) => (isGroupRecipient(recipient) ? getGroupRecipientWithStatus(recipient) : recipient)) ?? []
    )
  }, [data])

  const filteredTypes = filterOptionsByQuery(RECIPIENT_OPTIONS, typeQuery)
  const filteredStatus = filterOptionsByQuery(MAIL_DELIVERY_CATEGORY_OPTIONS, statusQuery)

  const close = () => dispatch({ type: 'ALERT_RECIPIENT_LIST_CLOSE' })

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => getRecipientEmail(row), {
        id: 'name',
        header: () => (
          <span className="pl-6 leading-7">
            <Trans>Name</Trans>
          </span>
        ),
        cell: (props) => {
          return <RecipientName recipientData={props.getValue()} row={props.row} />
        },
        sortingFn: (rowA, rowB, columnId) =>
          rowA
            .getValue<RecipientNameCellData>(columnId)
            .email.localeCompare(rowB.getValue<RecipientNameCellData>(columnId).email),
        filterFn: (row, _, filterValue) => handleSearchFilter(row.original, filterValue),
      }),
      columnHelper.accessor('type', {
        id: 'type',
        header: () => (
          <span className="leading-7">
            <Trans>Type</Trans>
          </span>
        ),
        cell: (props) => (
          <div className="flex h-8 w-fit items-center rounded bg-grey.7 px-3 text-label-l">
            <Trans>{RECIPIENT_OPTIONS.find((option) => option.value === props.getValue())?.label || 'Contact'}</Trans>
          </div>
        ),
        sortingFn: 'basic',
        filterFn: (row, columnId, filterValue) => {
          const type = row.getValue(columnId)
          // Select whole group if group or children matches
          if (
            (filterValue.includes('contact') && isGroupRecipient(row.original) && row.original.contacts.length) ||
            (!type && filterValue.includes('group'))
          ) {
            return true
          }
          return filterValue.includes(type ?? 'contact')
        },
      }),
      columnHelper.accessor(
        (row) => ({
          status: row.status,
          isMixed: 'isMixed' in row ? row.isMixed : false,
          message: 'message' in row ? row.message : undefined,
        }),
        {
          id: 'status',
          header: () => (
            <>
              <Trans>Status</Trans>
              <Tooltip>
                <TooltipTrigger>
                  <QuestionCircle24Regular className="ml-1 size-5" />
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent onClick={(e) => e.stopPropagation()}>
                    <Trans>
                      Status indicates the delivery status of the most recent alert email or the delivery status for a
                      specific alert selection.
                    </Trans>
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            </>
          ),
          cell: ({ getValue }) => (
            <StatusTag status={getValue().status} isMixed={getValue().isMixed} message={getValue().message} />
          ),
          sortingFn: statusSortingFunction,
          filterFn: (row, _, filterValue) => {
            if (isGroupRecipient(row.original)) {
              return row.original.contacts.some(({ status }) => filterValue.includes(status))
            }
            return filterValue.includes(row.original.status)
          },
        },
      ),
      columnHelper.display({
        enableSorting: false,
        id: 'actions',
        cell: ({ row }) =>
          !isGroupRecipientChild(row.original) &&
          hasAlertPermission && <RecipientRemoveButton id_recipient={row.original.id_recipient} />,
      }),
    ],
    [hasAlertPermission],
  )

  const columnFilters: ColumnFiltersState = useMemo(() => {
    return [
      ...(searchFilter ? [{ id: 'name', value: searchFilter }] : []),
      ...(selectedTypes.length ? [{ id: 'type', value: selectedTypes.map(({ value }) => value) }] : []),
      ...(selectedStatus.length ? [{ id: 'status', value: selectedStatus.map(({ value }) => value) }] : []),
    ]
  }, [searchFilter, selectedTypes, selectedStatus])

  const table = useReactTable({
    data: recipientsWithGroupStatus,
    columns,
    state: {
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => (isGroupRecipient(row) ? row.contacts : undefined),
    getFilteredRowModel: getFilteredRowModel(),
    sortDescFirst: false,
  })

  return (
    <>
      <Dialog open={open} onOpenChange={close}>
        <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="min-h-[32rem] gap-y-0">
          <DialogHeader>
            <DialogTitle className="flex gap-2">
              <Trans>Recipients</Trans>
              <div
                className={cn(
                  'flex min-w-20 items-center justify-start gap-1.5 overflow-hidden rounded bg-grey.7 px-2.5 py-0.5 text-label-l text-sky.1',
                  {
                    'animate-pulse': !alertName,
                  },
                )}
              >
                <Mail20Regular className="shrink-0" />
                {alertName && <span className="truncate">{alertName}</span>}
              </div>
            </DialogTitle>
          </DialogHeader>
          <DialogBody className="px-0 pt-4">
            <div className="mb-4 flex gap-2 px-6 pt-1">
              <InputGroup className="h-11 w-60">
                <Input
                  value={searchFilter}
                  placeholder={t('Search')}
                  onChange={(e) => setSearchFilter(e.target.value)}
                  aria-label={t('Search for filter recipients')}
                  className="h-full"
                />
                <InputRightElement>
                  {!searchFilter ? (
                    <Search20Regular />
                  ) : (
                    <button
                      type="button"
                      onClick={() => {
                        setSearchFilter('')
                      }}
                      className="rounded leading-none"
                    >
                      <Dismiss20Regular />
                      <span className="sr-only">{t('Clear')}</span>
                    </button>
                  )}
                </InputRightElement>
              </InputGroup>
              <MultiFilterDropdown value={selectedTypes} onChange={(value) => setSelectedTypes(value)}>
                <MultiFilterDropdownTrigger
                  value={selectedTypes}
                  label={selectedTypes.length > 1 ? selectedTypes.map(({ label }) => label).join(', ') : t('All')}
                  title={t('Type')}
                  icon={<Person20Regular />}
                />

                <MultiFilterDropdownContent query={typeQuery} onQueryChange={setTypeQuery} className="z-10">
                  {filteredTypes.length === 0 ? (
                    <div className="flex h-12 items-center justify-center text-grey.4">
                      <Trans>No results matching your search</Trans>
                    </div>
                  ) : (
                    filteredTypes.map((option) => <MultiFilterDropdownItem key={option.value} value={option} />)
                  )}
                </MultiFilterDropdownContent>
              </MultiFilterDropdown>
              <MultiFilterDropdown value={selectedStatus} onChange={(value) => setSelectedStatus(value)}>
                <MultiFilterDropdownTrigger
                  value={selectedStatus}
                  label={selectedStatus.length > 1 ? selectedStatus.map(({ label }) => label).join(', ') : t('All')}
                  title={t('Status')}
                  icon={<Megaphone20Regular />}
                />

                <MultiFilterDropdownContent query={statusQuery} onQueryChange={setStatusQuery} className="z-10">
                  {filteredStatus.length === 0 ? (
                    <div className="flex h-12 items-center justify-center text-grey.4">
                      <Trans>No results matching your search</Trans>
                    </div>
                  ) : (
                    filteredStatus.map((option) => <MultiFilterDropdownItem key={option.value} value={option} />)
                  )}
                </MultiFilterDropdownContent>
              </MultiFilterDropdown>
            </div>
            <RecipientTable table={table} queryStatus={status} />
          </DialogBody>
          <DialogFooter className="items-center sm:justify-between">
            <div className="flex gap-x-1">
              {(!!currentHistoryItem?.historyTimestamp || !!lastSent) && (
                <>
                  <Trans>Alert sent on</Trans>
                  <div>
                    {formatDate({
                      localeName: locale,
                      unixTimestamp: currentHistoryItem?.historyTimestamp
                        ? OpointTimestampToTimestamp(currentHistoryItem?.historyTimestamp)
                        : lastSent ?? 0,
                    })}
                  </div>
                </>
              )}
            </div>

            <DialogClose asChild>
              <Button variant="outline">
                <Trans>Close</Trans>
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <RecipientDeleteConfirmationModal />
    </>
  )
}

export default RecipientsListModal
