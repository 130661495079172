import { map } from 'ramda'
import {
  Setting,
  SettingWithChoices,
  SettingsResponse,
  SettingsSaveRequestPayload,
} from '../../components/interfaces/settings'
import config from '../common/config'
import { SUPPORTED_LOCALES } from '../common/constants'
import { handleErrors } from '../common'

const normalizeSettingValues = map((setting: Setting | SettingWithChoices<any>) => {
  if (setting.name === 'LANGUAGE' && !SUPPORTED_LOCALES.includes(setting.value)) {
    return { ...setting, value: 'en-GB' }
  }

  // due to original mistake in Chinese locale
  if (setting.value === 'zh_CN') {
    return { ...setting, value: 'zh-CN' }
  }

  if (setting.name === 'MAX_GT_ARTICLE_LENGTH') {
    return { ...setting, value: Number(setting.value) }
  }

  return setting
})

export const getSettings = async (): Promise<SettingsResponse> => {
  const url = config.url.api('/settings/')
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'GET',
  })

  // @ts-expect-error: Muted so we could enable TS strict mode
  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
    .then((response) => normalizeSettingValues(response))
}

export const saveSettings = async (settings: SettingsSaveRequestPayload): Promise<SettingsResponse> => {
  const url = config.url.api('/settings/')
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'POST',
    body: JSON.stringify(normalizeSettingValues(settings)),
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}
