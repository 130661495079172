import {
  allPass,
  assoc,
  compose,
  curry,
  dissoc,
  evolve,
  flatten,
  groupBy,
  isEmpty,
  lensProp,
  map,
  over,
  prepend,
  prop,
  propSatisfies,
  reject,
  slice,
  sortBy,
  splitEvery,
  trim,
} from 'ramda'
import { Profile } from '../../components/types/profile'
import config from '../common/config'
import { getDefaultTimezone } from '../common/constants'
import type {
  CommonFilterMetaDetails,
  ProfileDetail,
  ProfileHistoryFromApi,
  ProfileHistoryResult,
  SearchResult,
  SiteFilterMetaDetails,
  TBList,
} from '../flow'
import { SearchItem } from '../flow'
import { verifyProfile } from '../search'
import { handleErrors } from '../common'

export const isEmptySearchline: (searchline: SearchItem) => boolean = compose(
  allPass([propSatisfies(compose(isEmpty, trim), 'searchterm'), propSatisfies(isEmpty, 'filters')]),
  prop('searchline'),
)

/**
 * Strips empty searchlines from a profile
 */
// @ts-expect-error: Muted so we could enable TS strict mode
export const stripEmptySearchlines: (profile: ProfileDetail) => ProfileDetail = evolve({
  items: reject(isEmptySearchline),
})

export const profileHistorySegmentation = (
  results: Array<ProfileHistoryResult>,
): Array<Array<ProfileHistoryResult>> => {
  const splitedResults = splitEvery(7, results)
  const newResults = []

  for (let i = 1; i <= splitedResults.length; i *= 2) {
    // @ts-expect-error: Muted so we could enable TS strict mode
    newResults.push(slice(i - 1, i * 2 - 1, splitedResults))
  }

  return map(flatten, newResults)
}

/**
 * Retrieves profiles from API and transforms them into a Promise.
 * @returns {*}
 */
export const getProfiles = async (): Promise<Array<Profile>> => {
  const url = config.url.api('/profiles/')
  const request = new Request(url, { ...(await config.request.getRequestHeaders()), method: 'GET' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const saveProfile = async (profile: ProfileDetail): Promise<ProfileDetail> => {
  // We must dissociate id of each filter in this profile to avoid discrepancy
  // Backend will create new id for each filter.
  const profileWoItemsId = evolve({
    /* eslint-disable-line no-param-reassign */ items: map(dissoc('id')),
  })(profile)

  const isNewProfile = profileWoItemsId.id === null
  const method = isNewProfile ? 'POST' : 'PUT'
  const url = isNewProfile ? '/profiles/' : `/profiles/${profileWoItemsId.id}/`
  const body = compose(
    dissoc('allHistoryResults'),
    dissoc('history'),
    // @ts-expect-error: Muted so we could enable TS strict mode
  )(stripEmptySearchlines(isNewProfile ? dissoc('id', profileWoItemsId) : profileWoItemsId))
  const { items } = profileWoItemsId || {}
  // @ts-expect-error: Muted so we could enable TS strict mode
  const expressions = items?.map(({ searchline, linemode }) => ({ searchline, linemode }))

  // @ts-expect-error: Muted so we could enable TS strict mode
  return verifyProfile(expressions).then(async ({ searchresult: { debug } }: { searchresult: SearchResult }) => {
    if (debug) {
      return { debug }
    }

    const request = new Request(config.url.api(url), {
      ...(await config.request.getRequestHeaders()),
      method,
      body: JSON.stringify(body),
    })

    return fetch(request)
      .then(handleErrors)
      .then((response) => response.json())
  })
}

/**
 *  Get profile details from API
 */
export const getProfileDetail = async (profileId: number, locale: string): Promise<ProfileDetail> => {
  const url = config.url.api(`/profiles/${profileId}/`)

  const requestHeaders = await config.request.getRequestHeaders()
  requestHeaders.headers['accept-language'] = locale

  const request = new Request(url, { ...requestHeaders, method: 'GET' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
    .then((profileDetail) => {
      if (profileDetail.items.some(({ linemode }) => linemode === 'R')) {
        return evolve({
          items: sortBy(
            ({ linemode }) =>
              ({
                R: 0,
                O: 1,
                E: 2,
              }[linemode]),
          ),
        })(profileDetail)
      }

      // @ts-expect-error: Muted so we could enable TS strict mode
      return over(
        // @ts-expect-error: Muted so we could enable TS strict mode
        lensProp('items'),
        prepend({
          searchline: {
            searchterm: '',
            filters: [],
          },
          linemode: 'R',
        }),
      )(profileDetail)
    })
}

export const profileListToProfileTree = (list: Array<Profile>): Array<Profile> => {
  const parentTree = groupBy((profile) => profile.parent.toString(), list)

  const rootProfiles = parentTree[0]
  const setChildrenForProfile = (profile) =>
    assoc('children', (parentTree[profile.id] || [])?.map(setChildrenForProfile), profile)

  return rootProfiles ? map(setChildrenForProfile)(rootProfiles) : []
}

/**
 *  Get profile details from API
 */
export const getFilterMetasDetail = async (
  filterId: string,
  type: string,
  locale: string,
): Promise<TBList | SiteFilterMetaDetails[] | CommonFilterMetaDetails> => {
  const needLocale = type === 'list' || type === 'tblist' ? '' : getDefaultTimezone(locale) + '/'
  const url = config.url.api(`/filters/${type}/${filterId}/${needLocale}`)

  const requestHeaders = await config.request.getRequestHeaders()
  requestHeaders.headers['accept-language'] = locale

  const request = new Request(url, { ...requestHeaders, method: 'GET' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

/**
 * Given a flat array of profiles, it returns all direct children for a given profile.
 * It's curried so that we can generate multiple functions for different profiles.
 * @param profileId
 * @param profiles
 */
export const getChildrenForProfile = curry(
  (profileId: number, profiles: Array<Profile>): Array<Profile> =>
    // @ts-expect-error: Muted so we could enable TS strict mode
    compose(prop(profileId), groupBy(prop('parent')))(profiles),
)

/**
 * Given a flat array of profiles, it returns all nested children for a given profile.
 * Profiles are identified using profileId reference in Opoint's specification.
 * It's curried so that we can generate multiple functions for different profiles.
 * @param profileId
 * @param profiles
 */
export const getAllNestedChildrenForProfile = curry((profileId: number, profiles: Array<Profile>): Array<Profile> => {
  // let's map parent ids to children once
  // @ts-expect-error: Muted so we could enable TS strict mode
  const parentMap = groupBy(prop('parent'))(profiles)

  const getAllNestedChildren = (profileId) => {
    if (parentMap[profileId]) {
      return [].concat(parentMap[profileId], ...parentMap[profileId]?.map((x) => getAllNestedChildren(x.id)))
    }

    return []
  }

  return getAllNestedChildren(profileId)
})

/**
 * Delete profiles on backend
 */
export const deleteProfile = async ({
  id,
  locale = 'en-GB',
  force = false,
}: {
  id: number
  locale: string
  force: boolean
}): Promise<Array<Profile>> => {
  const requestHeaders = (await config.request.getRequestHeaders()).headers
  const url = config.url.api(`/profiles/${id}/${force ? '?force=1' : ''}`)

  return fetch(url, {
    headers: {
      ...requestHeaders,
      'accept-language': locale,
    },
    method: 'DELETE',
  })
    .then(handleErrors)
    .then((response) => {
      if (response.status === 204) {
        return {}
      }

      return response.json()
    })
}

export const getProfileHistory = async (profileId: number): Promise<ProfileHistoryFromApi> => {
  const url = config.url.api(`/profiles/${profileId}/history/`)
  const request = new Request(url, { ...(await config.request.getRequestHeaders()), method: 'GET' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const getDeletedProfiles = async (): Promise<ProfileHistoryFromApi> => {
  const url = config.url.api('/profiles/deleted/')
  const request = new Request(url, { ...(await config.request.getRequestHeaders()), method: 'GET' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const getProfileDependencies = async (id: number): Promise<any> => {
  const url = config.url.api(`/profiles/${id}/dependencies/`)
  const request = new Request(url, { ...(await config.request.getRequestHeaders()), method: 'GET' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const findParents = (node, id) => {
  // If current node name matches the search id, return
  // empty array which is the beginning of our parent result
  if (node.id === id) {
    return []
  }
  // Otherwise, if this node has a tree field/value, recursively
  // process the nodes in this tree array
  if (Array.isArray(node.children)) {
    for (const child of node.children) {
      // Recursively process child. If an array result is
      // returned, then add the child.id to that result
      // and return recursively
      const childResult = findParents(child, id)

      if (Array.isArray(childResult)) {
        return [child.id].concat(childResult)
      }
    }
  }
}

export const WATCH_INDEX_LIMIT = 99

export const HISTORY_TYPE = {
  DELETED_PROFILES: 'deletedProfiles',
  PROFILE_HISTORY: 'profileHistory',
} as const
