import { __, assoc, contains, dissoc, map } from 'ramda'
import { AlertPreviewResponse } from '../../components/types/alert'
import { CommonRecipientFilter, ContactFilter } from '../../components/types/contact'
import { AlertTag } from '../../components/types/tag'
import config from '../common/config'
import type { AlertDetailed } from '../flow'
import { NextAlert } from '../flow'
import { TAG_TYPES, TAG_VISIBILITY } from '../tags'
import { Alert, AlertContactOverview, AlertCreate, ResendAlertRecipients } from '../../api/opoint.schemas'

import { M360Article } from '../articles/types'
import { handleErrors } from '../common'
import { AlertHistoryObjectItem, AlertHistoryPreviewItem, AlertNextPreview } from './types'

/**
 * Constants
 */

export const WORKING_DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
export const WEEKEND_DAYS = ['saturday', 'sunday']

export const ALL_DAYS = [...WORKING_DAYS, ...WEEKEND_DAYS, 'holidays']

export const WHEN_ALERT = {
  WEEKDAYS_AT_8: 1,
  WEEKDAYS_AT_8_AND_2: 2,
  AS_SOON_AS_POSSIBLE: 3,
  ADVANCED: 4,
}

export const isWorkingDay = () => contains(__, WORKING_DAYS)
export const isWeekendDay = () => contains(__, WEEKEND_DAYS)

export const WhenAlert = {
  weekdays_at_8: 0,
  weekdays_at_8_and_2: 1,
  as_soon_as_possible: 3,
  advanced: 4,
}

export const recipientsEntitiesApiNormalize = (
  recipients: (ContactFilter | CommonRecipientFilter)[],
): ResendAlertRecipients[] =>
  recipients.map(({ type, entity: { id } }) => ({ type: type === 'phoneNumber' ? 'mobile' : type, value: `${id}` }))

/**
 * Functions
 */

export const getAlerts = async (): Promise<Alert[]> => {
  const url = config.url.api('/alerts/')
  const request = new Request(url, { ...(await config.request.getRequestHeaders()), method: 'GET' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const getAlertsRecipients = async (): Promise<Array<AlertContactOverview>> => {
  const url = config.url.api('/alerts/alert-contact-details/')
  const request = new Request(url, { ...(await config.request.getRequestHeaders()), method: 'GET' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const getAlert = async (id: number): Promise<AlertDetailed> => {
  const url = config.url.api(`/alerts/${id}/`)
  const request = new Request(url, { ...(await config.request.getRequestHeaders()), method: 'GET' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const getAlertNext = async (id: number): Promise<NextAlert> => {
  const url = config.url.api(`/alerts/${id}/next/`)
  const request = new Request(url, { ...(await config.request.getRequestHeaders()), method: 'GET' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const getAlertNextPreview = async (id: number): Promise<AlertNextPreview> => {
  const url = config.url.api(`/alerts/${id}/next/preview/`)
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'POST',
    body: JSON.stringify({ id, view: 'preview' }),
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

// TODO: Proper flowtype for history @Honza
export const getAlertHistory = async (id: number, page?: string): Promise<AlertHistoryObjectItem> => {
  const url = config.url.api(`/alerts/${id}/history/${page ? `?page=${page}` : ''}`)
  const request = new Request(url, { ...(await config.request.getRequestHeaders()), method: 'GET' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const getAlertInfo = async (mailLogId: number, hasJson: boolean = true) => {
  const url = config.url.api(`/alert_info/${mailLogId}?json=${hasJson}`)
  const request = new Request(url, { ...(await config.request.getRequestHeaders()), method: 'GET' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const getAlertTags = async (articles: any, baskets: any) => {
  const body = {
    baskets,
    articles,
  }

  const url = config.url.api('/tags/detect_tagged/')
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'POST',
    body: JSON.stringify(body),
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

// TODO: Proper flowtype for history @Honza
export const getAlertHistoryItem = async (
  alertId: number,
  historyId: number,
  timestamp: number,
): Promise<AlertHistoryPreviewItem> => {
  const url = config.url.api(`/alerts/${alertId}/history/${historyId}/${timestamp}/`)
  const request = new Request(url, { ...(await config.request.getRequestHeaders()), method: 'GET' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const saveAlert = async (alert: AlertCreate): Promise<AlertDetailed> => {
  const isNewAlert = alert.id === undefined

  const method = isNewAlert ? 'POST' : 'PUT'
  const url = isNewAlert ? '/alerts/' : `/alerts/${alert.id}/`
  const body = isNewAlert ? dissoc('id', alert) : alert

  const request = new Request(config.url.api(url), {
    ...(await config.request.getRequestHeaders()),
    method,
    body: JSON.stringify(body),
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const deleteAlert = async (alertId: string): Promise<AlertDetailed> => {
  const requestHeaders = (await config.request.getRequestHeaders()).headers
  const url = config.url.api(`/alerts/${alertId}/`)

  return fetch(url, {
    headers: requestHeaders,
    method: 'DELETE',
  })
    .then(handleErrors)
    .then((response) => {
      if (response.status === 204) {
        return {}
      }

      return response.json()
    })
}

export const sendNow = async (alertId: number): Promise<AlertDetailed> => {
  const url = config.url.api(`/alerts/${alertId}/send-now/`)
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'POST',
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const getArticlesToRemove = async (alertId: string, pageId: number): Promise<Array<M360Article>> => {
  const url = config.url.api(`/alerts/${alertId}/articles/?page=${pageId}`)
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'GET',
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const removeArticlesFromAlert = async (alertId: number, articles): Promise<Array<M360Article>> => {
  const body = {
    articles: Object.values(articles),
  }
  const url = config.url.api(`/alerts/${alertId}/articles/`)
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'POST',
    body: JSON.stringify(body),
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

// TODO: write tests
export const processAlertTags = (alerts: Alert[]): Array<AlertTag> => {
  // @ts-expect-error Muted so we could enable TS strict mode
  return alerts?.map((alert) => ({
    id: alert.id,
    children: map((a) => assoc('type', TAG_TYPES.ALERT, a), alert.baskets),
    subject: alert.subject,
    type: TAG_TYPES.ALERT,
    visibility: TAG_VISIBILITY.ALWAYS,
    expanded: false,
  }))
}

export const getAlertJsonPreview = async (id: number): Promise<AlertPreviewResponse[]> => {
  const url = config.url.api(`/alerts/${id}/next/preview/?json=1`)
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'POST',
    body: JSON.stringify({ id, view: 'preview' }),
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}
