import { DataTrending24Regular } from '@fluentui/react-icons'
import { t } from 'i18next'
import { ButtonHTMLAttributes, forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { getFirstStatistics } from '../../../../../selectors/statisticsSelectors'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useDefaultHomeUrl } from '../../../../hooks/useDefaultHomeUrl'
import { NavigationSection, NavigationSectionHeaderButton } from '../../primitives/NavigationSection'
import useHandleStatisticsSelection from '../../../actionBar/statistics/useHandleStatisticsSelection'
import { SavedStatisticsNavigationList } from './SavedStatisticsNavigationList'

export const SavedStatisticsNavigationSection = forwardRef<
  HTMLButtonElement,
  ({ isExpanded?: boolean } & ButtonHTMLAttributes<HTMLButtonElement>) | Record<string, never>
>(({ isExpanded, ...props }, ref) => {
  const navigate = useNavigate()

  const firstStatistics = useAppSelector(getFirstStatistics)
  const defaultHomeUrl = useDefaultHomeUrl()

  const { navigateToStatistics } = useHandleStatisticsSelection()

  const handleSavedStatisticsClick = () => {
    if (firstStatistics?.id) {
      navigateToStatistics(firstStatistics.id)
    } else {
      navigate(defaultHomeUrl)
    }
  }

  return (
    <NavigationSection>
      <NavigationSectionHeaderButton
        {...props}
        ref={ref}
        label={t('Saved statistics')}
        isExpanded={Boolean(isExpanded)}
        onClick={handleSavedStatisticsClick}
        data-cy="SavedStatisticsNavigationSection-button"
      >
        <DataTrending24Regular className="w-7 shrink-0 text-sky.4" />
      </NavigationSectionHeaderButton>
      <SavedStatisticsNavigationList />
    </NavigationSection>
  )
})
SavedStatisticsNavigationSection.displayName = 'SavedStatisticsNavigationSection'
