import { ImpersonationTarget } from '../../components/types/user'
import { handleErrors } from '../common'
import config from '../common/config'

/**
 * Retrieves user's alternate organizations for impersonation from API and transforms them into a promise.
 * @returns {*}
 */
export const getImpersonationOrganizations = async (): Promise<Array<ImpersonationTarget>> => {
  const queryParams = `?only_self=1` //TODO: We should probably handle page_size in some way. Default is 20

  const url = config.url.api(`/users/impersonation_targets/${queryParams}`)
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders(true)),
    method: 'GET',
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

/**
 * Retrieves user targets for impersonation from API and transforms them into a promise.
 * Results are ordered by user id, so use lastId for pagination.
 * @returns {*}
 */
export const getImpersonationUsers = async (userSearch?: string): Promise<Array<ImpersonationTarget>> => {
  const queryParams = `?search=${userSearch}&exclude_self=1&last_id_user=0`

  const url = config.url.api(`/users/impersonation_targets/${queryParams}`)
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders(true)),
    method: 'GET',
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}
