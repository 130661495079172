import { compose, isEmpty, length, map, reject, sum, take, values } from 'ramda'
import { GroupedArticles, ReportObject } from '../../components/types/reports'
import { handleErrors } from '../common'
import config from '../common/config'
import type { SearchItem } from '../flow'

export const REPORT_STATUS = {
  UPDATE_ERROR: -3,
  ERROR: -1,
  IN_PROGRESS: 0,
  FINISHED: 1,
}

export const REPORT_STEP_SELECT_CONTENT = 0
export const REPORT_STEP_SET_TEMPLATE = 1
export const REPORT_STEP_SORT_CONTENT = 2
export const REPORT_STEP_CREATE_REPORT = 3
export const REPORT_STEP_DOWNLOAD_SHARE = 4

export const reportsActionStep = {
  ACTION_SELECTION: 1,
  SHARE_ACTION: 2,
  SHARING_REPORT: 3,
  SHARE_REPORT_SUCCESS: 4,
  SHARE_REPORT_FAILURE: 5,
}

export const groupedArticlesLength = (groupedArticles: GroupedArticles) =>
  // @ts-expect-error: Muted so we could enable TS strict mode
  compose(sum, values, map(length))(groupedArticles)

export const takeGroupedArticles = (groupedArticles: GroupedArticles, number: number): any => {
  let count = number

  // @ts-expect-error: Muted so we could enable TS strict mode
  const selectedGroupedArticles = map((articleGroup) => {
    const selectedArticles = take(count, articleGroup)
    count -= selectedArticles.length

    return selectedArticles
  }, groupedArticles)

  return reject(isEmpty, selectedGroupedArticles)
}

export const getReportsTagHistory = async (id: number) => {
  const url = config.url.api(`/tags/${id}/reports/`)
  const request = new Request(url, { ...(await config.request.getRequestHeaders()), method: 'GET' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const createReport = async (params: {
  articles?: Array<{ id_site: number; id_article: number }>
  expressions?: Array<SearchItem>
  newest?: number
  oldest?: number
  sortedArticles?: Array<{ id_site: number; id_article: number }>
  templateId: number
  timezone: string
  title: string
  locale: string
  excludearticles: Array<{ id_site: number; id_article: number }>
}): Promise<any> => {
  const url = config.url.api('/reports/?task=async')

  const requestHeaders = await config.request.getRequestHeaders()
  requestHeaders.headers['accept-language'] = params?.locale

  const request = new Request(url, {
    ...requestHeaders,
    method: 'POST',
    // @ts-expect-error: Muted so we could enable TS strict mode
    body: JSON.stringify({ timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, ...params }),
  })

  return fetch(request)
    .then(handleErrors)
    .then(async (response) => {
      const status = response.status
      return { ...(await response.json()), status }
    })
}

export const shareReport = async (params: {
  id?: Array<number>
  message?: string
  recipients?: {
    type: 'email' | 'person' | 'group' | 'user' | 'phoneNumber'
    id: string | number
    value?: string
  }[]
  shareAttachment?: boolean
}): Promise<object> => {
  const url = config.url.api('/reports/share/')
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'POST',
    body: JSON.stringify({ timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, ...params }),
  })

  return fetch(request).then(handleErrors)
}

export const deleteReport = async (params: { id?: Array<number> }): Promise<object> =>
  fetch(config.url.api(`/reports/${params.id}/`), {
    ...(await config.request.getRequestHeaders()),
    method: 'DELETE',
  }).then(handleErrors)

export const checkReportStatus = async (params: { id: number }): Promise<ReportObject> => {
  const url = config.url.api(`/reports/${params.id}/`)
  const request = new Request(url, { ...(await config.request.getRequestHeaders()), method: 'GET' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}
