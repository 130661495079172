import { Delete24Regular } from '@fluentui/react-icons'
import { Button, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@opoint/infomedia-storybook'
import { useEffect, useState } from 'react'

import { ActionBarDropdownMenuItem } from '../../ActionBarDropdownMenuItem'
import { ActionBarActionsProps, ActionVariant } from '../../types'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { getDeleteAlertInProgress } from '../../../../../selectors/alertsSelectors'
import AlertDeleteConfirmationDialog from '../../../../alerts/AlertDeleteConfirmationDialog'
import { useRequiredParams } from '../../../../hooks/useRequiredParams'

const AlertDeleteAction = ({ variant = ActionVariant.LONG, name }: ActionBarActionsProps) => {
  const { alertId } = useRequiredParams(['alertId'])
  const [showAlert, setShowAlert] = useState(false)
  const onClickAction = () => setShowAlert(true)

  const isDeleteInProgress = useAppSelector(getDeleteAlertInProgress)

  useEffect(() => {
    if (!isDeleteInProgress) {
      setShowAlert(false)
    }
  }, [isDeleteInProgress])

  const trigger = () => {
    if (variant === ActionVariant.MENU) {
      return (
        <ActionBarDropdownMenuItem onClick={onClickAction} data-cy="deleteAlert">
          {name}
        </ActionBarDropdownMenuItem>
      )
    }

    if (variant === ActionVariant.LONG) {
      return (
        <Button variant="outline" onClick={onClickAction} data-cy="deleteAlert">
          <Delete24Regular />
          {name}
        </Button>
      )
    }

    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <Button variant="outline" size="icon" onClick={onClickAction} data-cy="deleteAlert">
            <Delete24Regular />
            <span className="sr-only">{name}</span>
          </Button>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent>{name}</TooltipContent>
        </TooltipPortal>
      </Tooltip>
    )
  }

  return (
    <>
      {trigger()}
      <AlertDeleteConfirmationDialog alertId={+alertId} show={showAlert} onClose={() => setShowAlert(false)} />
    </>
  )
}

export default AlertDeleteAction
