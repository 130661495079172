import type { ArticleTag } from '@opoint/infomedia-storybook'
import { __, curry, flatten, map, pluck, propEq, reject } from 'ramda'
import type { GeneralTag, Tag, TagCreate } from '../../components/types/tag'
import { getAllIdenticalArticles, LogActions, logArticleAction } from '../articles'
import config from '../common/config'
import type { TagWeight } from '../flow'
import { DocumentIdenticalDocuments } from '../../api/opoint-search-suggest.schemas'
import { M360Article } from '../articles/types'
import { handleErrors } from '../common'

/**
 * Constansts
 */

export enum TAG_TYPES {
  GLOBAL_TRASH = 4,
  KEYWORD = 1,
  MENTOMETER = 2,
  ALERT = 3,
  PROFILE_TRASH = 0,
}
export const TAG_VALUES = {
  // keyword
  1: {
    MAX: 6,
    DEFAULT: 1,
    MIN: 1,
  },
  // mentometer
  2: {
    MAX: 3,
    DEFAULT: 0,
    MIN: -3,
  },
  // alert
  3: {
    MAX: 7,
    DEFAULT: 1,
    MIN: 1,
  },
}
export const TAG_VISIBILITY = {
  WHEN_SET: 1,
  ALWAYS: 2,
}

// If there is exactly one tag, take its id instead of parent id
function getAlertTagId(tag: Tag) {
  return tag.children && tag.children.length === 1 ? tag.children[0].id : tag.id
}

/**
 * Functions
 */
export function getIncrementedWeight({ articleTag, tag }: { articleTag?: ArticleTag; tag: Tag }) {
  const { type } = tag
  const { weight = TAG_VALUES[type].DEFAULT } = articleTag || {}

  return weight < TAG_VALUES[type].MAX ? weight + 1 : TAG_VALUES[type].MIN
}

export function getDecrementedWeight({ articleTag, tag }: { articleTag?: ArticleTag; tag: Tag }) {
  const { type } = tag
  const { weight = TAG_VALUES[type].DEFAULT } = articleTag || {}

  return weight > TAG_VALUES[type].MIN ? weight - 1 : TAG_VALUES[type].MAX
}

export function isTheWholeGroupUntagged(article: M360Article, tag: Tag) {
  return !article.tags?.[tag.id]
}

export function isEveryArticleInGroupUntagged(
  identicalArticles: Array<M360Article>,
  tagToToggle: Tag,
  untaggingArticle: M360Article,
) {
  const articles = reject(propEq('id_article', untaggingArticle.id_article), identicalArticles)
  let is = true
  let i = 0
  while (is && i < articles.length) {
    const identicalArticle = articles[i]
    if (identicalArticle.tags?.[tagToToggle.id]) {
      is = false
    }
    i += 1
  }

  return is
}

export function getTaggedIdenticalArticlesCount(
  originalArticleTags: M360Article['tags'],
  tag: GeneralTag,
  identicalDocuments?: DocumentIdenticalDocuments,
) {
  if (identicalDocuments && identicalDocuments.cnt !== 0) {
    const tagId = `${tag.id}`
    let countIdent = 0

    if (originalArticleTags?.[tagId]) {
      countIdent = identicalDocuments.document.reduce((summ, identArticle) => {
        if (identArticle.tags?.[tagId]) {
          return summ + 1
        }

        return summ
      }, 0)
    }

    return (
      !!countIdent &&
      countIdent !== identicalDocuments.document.length &&
      `${countIdent}/${identicalDocuments.document.length}`
    )
  }

  return null
}

export const lastSort = async (tagId: number) => {
  const url = config.url.api(`/tags/${tagId}/last-sorted/`)
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'GET',
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const getTags = async (): Promise<Array<Tag>> => {
  const url = config.url.api('/tags/')
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'GET',
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const tagArticles = async (
  articles: Array<M360Article>,
  tag: Tag,
  weight: TagWeight,
): Promise<{ articles: Array<M360Article>; tag: Tag; status: any }> => {
  const { type } = tag
  const id = type === TAG_TYPES.ALERT ? getAlertTagId(tag) : tag.id
  const allArticles = flatten(map(getAllIdenticalArticles, articles))
  const articlesIdsFn = ({ id_site, id_article, internal_search_reply, stimestamp }) => ({
    id_site,
    id_article,
    stimestamp,
    matchinfo: internal_search_reply.text,
  })

  const requestHeaders = (await config.request.getRequestHeaders()).headers
  const url = config.url.api(`/tags/${id}/articles/tag/`)

  allArticles?.forEach((article) => {
    logArticleAction({
      id_article: article.id_article,
      id_site: article.id_site,
      action: [LogActions.ArticleAddedToTag],
    })
  })

  return fetch(url, {
    headers: requestHeaders,
    method: 'POST',
    body: JSON.stringify({
      articles: allArticles?.map(articlesIdsFn),
      weight: weight || TAG_VALUES[type].DEFAULT,
    }),
  })
    .then(handleErrors)
    .then((response) => {
      if (response.status === 204) {
        return {}
      }

      return response.json()
    })
}

export const untagArticles = async (
  articles: Array<M360Article>,
  tagId: number,
  weight: TagWeight = 1,
): Promise<{ articles: Array<M360Article>; tag: Tag; status: any }> => {
  const allArticles = flatten(map(getAllIdenticalArticles, articles))

  const articlesIdsFn = ({ id_site, id_article, stimestamp }) => ({ id_site, id_article, stimestamp })

  const requestHeaders = (await config.request.getRequestHeaders()).headers
  const url = config.url.api(`/tags/${tagId}/articles/untag/`)

  return fetch(url, {
    headers: requestHeaders,
    method: 'POST',
    body: JSON.stringify({
      articles: allArticles?.map(articlesIdsFn),
      weight,
    }),
  })
    .then(handleErrors)
    .then(handleErrors)
    .then((response) => {
      if (response.status === 204) {
        return {}
      }

      return response.json()
    })
}

export const untagSingleArticle = async (
  article: M360Article,
  tag: Tag,
  weight: TagWeight = 1,
): Promise<{ articles: Array<M360Article>; tag: Tag; status: any }> => {
  const id = tag.type === TAG_TYPES.ALERT ? getAlertTagId(tag) : tag.id

  /* eslint-disable-next-line */
  const { id_site, id_article, stimestamp } = article

  const url = config.url.api(`/tags/${id}/articles/untag/`)
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'POST',
    body: JSON.stringify({
      articles: [{ id_site, id_article, stimestamp }],
      weight,
    }),
  })

  return (
    fetch(request)
      .then(handleErrors)
      .then((response) => response.json())
      .then(({ response, response: { status } }) => ({ articles: [article], tag: response, status }))
      // @ts-expect-error Muted so we could enable TS strict mode
      .catch(({ status }) => Promise.reject(new Error({ articles: [article], status })))
  )
}

export const tagSingleArticle = async (
  article: M360Article,
  tag: Tag,
  weight: TagWeight,
): Promise<{ articles: Array<M360Article>; tag: Tag; status: any }> => {
  const { type } = tag
  const id = type === TAG_TYPES.ALERT ? getAlertTagId(tag) : tag.id

  /* eslint-disable-next-line */
  const { id_site, id_article, internal_search_reply, stimestamp } = article

  const url = config.url.api(`/tags/${id}/articles/tag/`)
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'POST',
    body: JSON.stringify({
      articles: [
        {
          id_site,
          id_article,
          matchinfo: internal_search_reply.text,
          stimestamp,
        },
      ],
      weight: weight || TAG_VALUES[type].DEFAULT,
    }),
  })

  logArticleAction({ id_article, id_site, action: [LogActions.ArticleAddedToTag] })

  return (
    fetch(request)
      .then(handleErrors)
      .then((response) => response.json())
      .then(({ response, response: { status } }) => ({ articles: [article], tag: response, status }))
      // @ts-expect-error Muted so we could enable TS strict mode
      .catch(({ status }) => Promise.reject(new Error({ articles: [article], status })))
  )
}

export const toggleTagVisibility = (tag: Tag): Tag => {
  return { ...tag, visibility: tag.visibility === 2 ? 1 : 2 }
}

export const isTagOnArticle = (tag: Tag, a: M360Article): boolean => {
  /* eslint-disable-next-line no-underscore-dangle */
  const articleTags = map(curry(parseInt)(__, 10), Object.keys(a.tags))
  const children = tag.children ? pluck('id', tag.children) : []
  const ids = [tag.id, ...children]

  // @ts-expect-error Muted so we could enable TS strict mode
  return ids.reduce((prev, curr) => articleTags.includes(curr) || prev, false)
}

export const addTag = async (tag: TagCreate): Promise<Tag> => {
  const url = config.url.api('/tags/')
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'POST',
    body: JSON.stringify(tag),
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const editTag = async (tag: Tag): Promise<Tag> => {
  const url = config.url.api(`/tags/${tag.id}/`)
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'PUT',
    body: JSON.stringify(tag),
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const deleteTag = async (tagId: number): Promise<void> => {
  const url = config.url.api(`/tags/${tagId}/`)
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'DELETE',
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.text())
}
