import { getStatisticsList } from '../../../../../selectors/statisticsSelectors'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import useHandleStatisticsSelection from '../../../actionBar/statistics/useHandleStatisticsSelection'
import { useNavigationContext } from '../../NavigationContext'
import {
  NavigationSectionContent,
  NavigationSectionEmptySearchMessage,
  NavigationSectionSearchItem,
  NavigationSectionSearchItemButton,
} from '../../primitives/NavigationSection'

export const SavedStatisticsNavigationList = () => {
  const statistics = useAppSelector(getStatisticsList)

  const { searchQuery } = useNavigationContext()
  const { navigateToStatistics } = useHandleStatisticsSelection()

  if (!searchQuery) {
    return null
  }

  const searchStatistics = statistics.filter(({ name }) => name.toLowerCase().includes(searchQuery.toLowerCase()))

  return (
    <NavigationSectionContent>
      {!searchStatistics.length ? (
        <NavigationSectionEmptySearchMessage />
      ) : (
        searchStatistics.map((statistics) => (
          <NavigationSectionSearchItem key={statistics.id}>
            <NavigationSectionSearchItemButton onClick={() => navigateToStatistics(statistics.id)}>
              {statistics.name}
            </NavigationSectionSearchItemButton>
          </NavigationSectionSearchItem>
        ))
      )}
    </NavigationSectionContent>
  )
}
